export default {
    methods: {
        async handleSubmit() {
            let stepIdx = 0;
            console.log(this.selected)
            for (const key in this.selected) {
                if (this.selected[key] === null) {
                    this.$toasted.error('Необходимо выбрать ' + this.step_data_map[key], {
                        position: "bottom-right"
                    })
                    this.tabIndex = stepIdx;
                    return
                }
                stepIdx++;
            }
            try {
                const {data} = await this.$axios.post('api/mailing/email/message', {
                    template_id: this.selected.template,
                    contact_email: this.selected.sender,
                    theme: this.selected.theme,
                    sender_name: this.selected.sender_name,
                    list_id: this.selected.contact
                });
                console.log(data)
                if (data && data.result) {
                    this.$toasted.success('Успех', {
                        position: "bottom-right"
                    });
                    await this.$router.push({name: 'EmailSend'})
                }
            } catch (e) {
                console.log(e)
                this.$toasted.error('Произошла ошибка', {
                    position: "bottom-right"
                });
            }

        },
        async loadReqs() {
            try {
                this.toggleLoader()
                const {data} = await this.$axios.get('api/mailing/email/message/create');
                const requiredFields = ['checked_emails', 'emails', 'templates']
                if (data) {
                    for (const key in data) {
                        if (requiredFields.includes(key)) {
                            if (data[key] && data[key]['result']) {
                                this.step_data[key] = data[key]['result']
                            }
                        }
                    }
                }
                this.toggleLoader()
                console.log('sssss', data);
            } catch (e) {
                this.toggleLoader()
                console.log(e)
            }
        }
    }
};
