<template>
  <div class="email__card" :class="{'checked': checked}">
    <div class="card">
      <!--      {{ card }}-->
      <h5 class="card__title">{{ card.title }}</h5>
      <div class="card__image">
        <div class="card__image__overlay">
          <a class="overlay__btn" href="#" @click.prevent="showDetail">подробнее</a>
          <a class="overlay__btn_screen" :href="card.fullsize_screenshot_url || card.screenshot_url" target="_blank">показать скриншот</a>
        </div>
        <img :src="card.fullsize_screenshot_url || card.screenshot_url" alt="#">
      </div>
      <a class="card__button" :class="{'active': checked}" href="#" @click.prevent="toggleCard">Использовать</a>
    </div>
    <!--    -->
  </div>
</template>

<script>
import moment from "moment";
import MailTemplateModal from "./Modal/MailTemplateModal";

export default {
  name: "EmailTemplateCard",
  props: ['card', 'selectedId'],
  data() {
    return {
      check: false,
    }
  },
  computed: {
    checked() {
      return this.card.id === this.selectedId;
    }
  },
  methods: {
    toggleCard() {
      this.$emit('checked', {
        checked: !this.checked,
        card: this.card
      })
    },
    showDetail() {
      this.$modal.show(MailTemplateModal, {
        recordId: this.card.id,
        html: this.card.body
      }, { name: 'MailTemplateModal', width: '70%', height: 'auto', scrollable: true });
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin active {
  text-decoration: none;
  background: #002B49;
  color: #fff;
}

.email__card {
  width: 20%;
  padding: 7.5px;

  .card {
    background: #fff;
    border: none;
    padding: 15px;
    height: 100%;
    box-shadow: 0px 4px 20px rgba(#000, .1);
    transition: .2s;


    &:hover {
      box-shadow: 0px 4px 20px rgba(#000, .2);
    }

    &__title {
      font-size: 18px;
      font-weight: normal;
      margin-bottom: 15px;
    }

    &__image {
      width: 100%;
      height: 200px;
      margin-bottom: 15px;
      margin-top: auto;
      position: relative;
      &:hover {
        .card__image__overlay {
          opacity: 1;
        }
      }
      &__overlay {
        &.active {
          opacity: 1;
        }
        transition: .2s;
        position: absolute;
        opacity: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background: rgba(#000, .5);
        justify-content: center;
        align-items: center;

        .overlay__btn {
          padding: 5px 15px;
          color: #000;
          font-weight: bold;
          background: #eee;
          display: block;
          &_screen {
            margin-top: 5px;
            color: #fff;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__button {
      text-align: center;
      display: block;
      width: 100%;
      padding: 5px 15px;
      text-transform: lowercase;
      font-weight: normal;
      color: #333;
      box-shadow: 0px 1px 5px rgba(#000, .2);
      transition: .2s;

      &:hover {
        @include active;
      }

      &.active {
        @include active;
      }

      //border: 1px solid #333;
    }
  }
}
</style>