<template>
  <div class="contact">
    <div class="contact__wrapper" @click="pickContact">
      <div class="wrapper__picker" :class="{'picked': isPicked}"><i class="fa fa-check" aria-hidden="true"></i></div>
      <div class="contact__head">
        <h4 class="contact__title">
          {{ contact.title }}
        </h4>
        <div class="contact__detail">
          <p v-if="contactInfo" class="m-0 text-right">
            <i class="fa fa-user mr-2"></i>{{ contactInfo.count }}
          </p>
          <a v-else href="" @click.prevent="getContactDetail">подробнее  <b-spinner v-if="fetching" small label="Small Spinner"></b-spinner></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailContact",
  props: ['contact', 'activeContact', 'disabled'],
  data() {
    return {
      contactInfo: null,
      fetching: false,
    }
  },
  computed: {
    isPicked() {
      return this.activeContact === this.contact.id
    }
  },
  methods: {
    pickContact() {
      if (!this.disabled) {
        this.$emit('contactPicked', this.contact.id)
      }
    },
    async getContactDetail() {
      try {
        this.fetching = true;
        const {data} = await this.$axios.get('api/mailing/email/contact/'+this.contact.id)
        if (data && data.result) {
          this.contactInfo = data.result
        }
        console.log(data)
      } catch (e) {
        console.log(e)
      }
      this.fetching = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.contact {
  width: 20%;
  padding: 7.5px;
  &__wrapper {
    box-shadow: 0px 2px 10px rgba(#000, .1);
    padding: 15px;
    border-radius: 5px;
    transition: .2s;
    cursor: pointer;
    position: relative;

    .wrapper__picker {
      display: none;
      position: absolute;
      right: 15px;
      color: #fff;
      width: 15px;
      height: 15px;
      background: #4eb76e;
      border-radius: 50%;
      &.picked {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:hover {
      box-shadow: 0px 3px 10px rgba(#000, .2);
    }
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: #444;
  }
  &__head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__detail {
    a {
      color: #777;
      font-weight: normal;
    }
  }
}
</style>