<template>
  <div>
    <div v-html="this.html">
    </div>
  </div>
</template>

<script>
export default {
  name: "MailTemplateModal",
  props: ['html', 'recordId']
}
</script>

<style scoped>

</style>